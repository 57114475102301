/**
 * Retorna o separador decimal de um locale.
 * @param locale - string do tipo BCP 47. (ex.: pt-BR, en-US, etc.)
 */
export const getDecimalSeparator = (locale: string): string => {
  const numberExample = 1.1;
  return numberExample.toLocaleString(locale).substring(1, 2);
};

export function removeNonNumericsExceptDash(raw: string) {
  const dash = raw?.charAt(0) === '-' ? '-' : '';
  return `${dash}${raw?.replace(/[^0-9]/g, '')}`;
}

export function removeNonNumerics(raw: string) {
  return raw?.replace(/[^0-9]/g, '');
}
