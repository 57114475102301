import {
  Image,
  ImageProps,
  Tooltip,
  HStack,
  Link,
  IconProps,
} from '@chakra-ui/react';
import JstFacebook from 'components/icons/SocialIcons/JstFacebook';
import JstInstagram from 'components/icons/SocialIcons/JstInstagram';
import JstWhatsapp from 'components/icons/SocialIcons/JstWhatsapp';
import { useScreenClass } from 'react-grid-system';

const commonProps = {
  width: '36px',
  height: '36px',
};

export const Facebook = (props: IconProps) => (
  <Tooltip label="Visite nossa página no Facebook.">
    <Link href="https://facebook.com/justacomvc" target="_blank">
      <JstFacebook {...props} />
    </Link>
  </Tooltip>
);

export const Instagram = (props: IconProps) => (
  <Tooltip label="Visite nossa página no Instagram.">
    <Link href="https://instagram.com/justacomvc" target="_blank">
      <JstInstagram {...props} />
    </Link>
  </Tooltip>
);

export const LinkedIn = (props: ImageProps) => (
  <Tooltip label="Visite nossa página no LinkdIn.">
    <Link
      href="https://www.linkedin.com/company/justa-solucoes-financeiras"
      target="_blank"
    >
      <Image
        cursor="pointer"
        {...props}
        src="/img/linkedin.svg"
        title="LinkedIn Justa"
        {...commonProps}
      />
    </Link>
  </Tooltip>
);

export const WhatsApp = (props: IconProps) => (
  <Tooltip label="Precisa de ajuda? Entre em contato pelo nosso WhatsApp.">
    <Link
      href="https://api.whatsapp.com/send?phone=551140001688"
      target="_blank"
    >
      <JstWhatsapp {...props} />
    </Link>
  </Tooltip>
);

const SocialIcons = () => {
  const screenClass = useScreenClass();
  const isMobile = !['xxl', 'xl', 'lg'].includes(screenClass);
  const iconProps = {
    color: isMobile ? '#213A62' : 'white',
    boxSize: isMobile ? '24px' : '31px',
  };

  return (
    <HStack spacing={isMobile ? 6 : 8} mt={isMobile ? 12 : 0}>
      <Facebook {...iconProps} />
      <Instagram {...iconProps} />
      <WhatsApp {...iconProps} />
    </HStack>
  );
};
export default SocialIcons;
