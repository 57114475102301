import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { useScreenClass } from 'react-grid-system';

const SocialIcons = dynamic(() => import('components/SocialIcons'), {
  ssr: false,
});

type Props = FlexProps & {
  children: React.ReactNode;
  message?: React.ReactNode;
};

const AuthFormArea = ({ children, message, ...rest }: Props) => {
  const screenClass = useScreenClass();
  const isMobile = !['xxl', 'xl', 'lg'].includes(screenClass);
  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      h="100% !important"
      maxH={670}
      maxW={502}
      w="100%"
      {...rest}
      px={6}
    >
      {message}
      {children}
      <Flex flexDir="row" alignItems="center" justifyContent="center" mb={10}>
        {isMobile ? <SocialIcons /> : null}
      </Flex>
    </Flex>
  );
};

export default AuthFormArea;
