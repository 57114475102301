import { Box, Link, Text, IconButton } from '@chakra-ui/react';
import Image from 'next/image';
import React from 'react';
import 'react-smartbanner/dist/main.css';
import Button from 'components/Button';
import { SettingsList, Setting } from './types';
import { FiX } from 'react-icons/fi';
import { isClient } from 'utils/isClient';
import { daysToSeconds } from 'utils/daysToSeconds';
import { parseCookies, setCookie } from 'nookies';
import { useSideNavStore } from 'store/sidenavstore';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ua = require('ua-parser-js');

const SmartBannerBase = () => {
  const [appId, setAppId] = React.useState('');
  const [type, setType] = React.useState('');
  const [settings, setSettings] = React.useState<Setting>();
  const [display, setDisplay] = React.useState('none');
  const cookies = parseCookies();
  const token = cookies.authToken;

  const { sideNavIsOpened } = useSideNavStore();

  //Variável necessária para o link da loja ioS
  const appStoreLanguage = isClient
    ? window.navigator.language.slice(-2) || 'us'
    : 'us';

  const userDismissedBanner =
    cookies['smartbanner-closed'] || cookies['smartbanner-installed'];

  /**Retorna configurações para cada tipo de smartphone que inclui:
   *  1) appMeta:  loja do disposito
   *  2) getStoreLink: função que retorna a url do app
   * */
  const getSettingsByType = React.useCallback(
    (actualType: string) => {
      const mixins: SettingsList = {
        ios: {
          appMeta: () => 'apple-itunes-app',
          getStoreLink: () =>
            `https://itunes.apple.com/${appStoreLanguage}/app/id`,
        },
        android: {
          appMeta: () => 'google-play-app',
          getStoreLink: () => 'http://play.google.com/store/apps/details?id=',
        },
        windows: {
          appMeta: () => 'msApplication-ID',
          getStoreLink: () => 'http://www.windowsphone.com/s?appid=',
        },
      };

      switch (actualType) {
        case 'ios':
          return mixins.ios;
        case 'android':
          return mixins.android;
        case 'windows':
          return mixins.windows;
        default:
          return undefined;
      }
    },
    [appStoreLanguage],
  );

  /**
   * Retorna o ID do app, dependendo da loja do smartphone
   * */
  const parseAppId = React.useCallback(
    (actualType: string) => {
      const setting = getSettingsByType(actualType);
      setSettings(setting);
      const meta = window.document.querySelector(
        `meta[name="${setting?.appMeta()}"]`,
      );
      let keepAppId = '';

      if (actualType === 'windows') {
        keepAppId = meta?.getAttribute('content') as string;
      } else {
        const content = /app-id=([^\s,]+)/.exec(
          meta?.getAttribute('content') as string,
        );
        if (content && content[1]) {
          keepAppId = content[1];
        }
      }
      setAppId(keepAppId);
    },
    [getSettingsByType, setSettings, setAppId],
  );

  const hide = () => {
    setDisplay('none');
    if (isClient) {
      window?.document
        ?.querySelector('html')
        ?.classList.remove('smartbanner-show');
    }
  };

  const close = () => {
    hide();
    setCookie(null, 'smartbanner-closed', 'true', {
      path: '/',
      maxAge: daysToSeconds(15),
    });
  };

  const install = () => {
    hide();
    setCookie(null, 'smartbanner-installed', 'true', {
      path: '/',
      maxAge: daysToSeconds(90),
    });
  };

  const retrieveLink = () => {
    return settings?.getStoreLink() + appId;
  };

  // UseEffect para identificar se enquadra em algum tipo de smartphone e então salvar o ID do App
  React.useEffect(() => {
    const documentRoot = window.document.querySelector('html');

    documentRoot?.classList.remove('smartbanner-show');

    if (isClient) {
      const agent = ua(window.navigator.userAgent);

      let keepType = '';

      if (
        agent.os.name === 'Windows Phone' ||
        agent.os.name === 'Windows Mobile'
      ) {
        keepType = 'windows';
        // iOS >= 6 has native support for Smart Banner
      } else if (
        agent.os.name === 'iOS' &&
        (parseInt(agent.os.version, 10) < 6 ||
          agent.browser.name !== 'Mobile Safari')
      ) {
        keepType = 'ios';
      } else if (
        agent.device.vender === 'Amazon' ||
        agent.browser.name === 'Silk'
      ) {
        keepType = 'kindle';
      } else if (agent.os.name === 'Android') {
        keepType = 'android';
      }
      if (keepType) {
        setType(keepType);
        parseAppId(keepType);
      }
    }
  }, [setType, parseAppId]);

  React.useEffect(() => {
    if (isClient) {
      setDisplay('flex');
      window?.document
        ?.querySelector('html')
        ?.classList.add('smartbanner-show');
    }
  }, []);

  // Não mostra o banner quando:
  // 1) Não encontrou o client
  // 2) O dispositivo não é iOS ou Android
  // 3) Usuário dispensa o banner,
  // 4) Não foi encontrado o id do App
  if (!isClient || !type || userDismissedBanner || !appId) {
    return null;
  }

  return (
    <Box
      zIndex="banner"
      position="fixed"
      bottom={0}
      background="#086FB0"
      w="100vw"
      h="94px"
      display={display}
      alignItems="center"
      justifyContent="space-between"
      p="16px 24px"
      fontFamily="Montserrat"
    >
      <IconButton
        border="1px solid #FFFFFF"
        background="#086FB0"
        aria-label="Fechar Banner"
        borderRadius="13px"
        width="20px"
        minW="20px"
        height="20px"
        onClick={() => close()}
        icon={<FiX color="white" />}
        position="absolute"
        bottom="84px"
        right={
          !token || !sideNavIsOpened
            ? { base: '10px', md: '17vw', lg: '14vw', xl: '10vw' }
            : { base: '10px', md: '32vw', lg: '27vw', xl: '25vw' }
        }
      />
      <Box display="flex" alignItems="center">
        <Image width="65vw" height="65vw" src="/img/icon-justa-app.svg" />
        <Box ml="16px">
          <Text fontSize="14px" fontWeight={600} color="white">
            Conta Justa:
          </Text>
          <Text fontSize="14px" color="white" fontWeight={300}>
            Gratuita
          </Text>
        </Box>
      </Box>
      <Link href={retrieveLink()}>
        <Button
          w="30vw"
          onClick={() => install()}
          background="#043C68"
          color="white"
          mr={{ md: !token || !sideNavIsOpened ? '16vw' : '30vw' }}
        >
          Baixar
        </Button>
      </Link>
    </Box>
  );
};

export default SmartBannerBase;
