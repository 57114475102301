import React from 'react';
import { ButtonProps } from '@chakra-ui/react';
import * as S from './styles';

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => (
    <S.Wrapper ref={ref} {...props}>
      {children}
    </S.Wrapper>
  ),
);

Button.displayName = 'Button';

export default Button;
