import React from 'react';
import { useRouter } from 'next/router';
import { Text } from '@chakra-ui/react';
import { useScreenClass } from 'react-grid-system';

const FormMessages = () => {
  const router = useRouter();
  const { pathname } = router;
  const screenClass = useScreenClass();
  const isMobile = !['lg', 'xl', 'xxl'].includes(screenClass);

  if (pathname === '/recuperar-senha') {
    return (
      <Text
        mb={4}
        mt={isMobile ? 4 : 0}
        fontSize={isMobile ? '3xl' : '3rem'}
        color="#183B65"
        fontFamily="Montserrat"
        fontWeight={500}
        lineHeight="58px"
      >
        Recuperar <Text as="strong">Senha</Text>
      </Text>
    );
  }

  if (pathname === '/criar-senha/[token]') {
    return (
      <Text
        mb={4}
        mt={isMobile ? 4 : 0}
        fontSize={isMobile ? '3xl' : '3rem'}
        color="#183B65"
        fontFamily="Montserrat"
        fontWeight={500}
        lineHeight="58px"
      >
        Criar nova <Text as="strong">Senha</Text>
      </Text>
    );
  }

  if (isMobile) {
    return null;
  }

  return (
    <Text
      fontSize="3rem"
      color="#183B65"
      fontFamily="Montserrat"
      fontWeight={500}
      lineHeight="58px"
    >
      Seja bem-vindo(a) à <Text as="strong">Conta Justa</Text>
    </Text>
  );
};

export default FormMessages;
