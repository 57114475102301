import React from 'react';
import { Input as BaseInput, InputProps } from '@chakra-ui/react';

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <BaseInput
    fontSize="medium"
    padding={4}
    focusBorderColor="brand.500"
    ref={ref}
    {...props}
    _readOnly={{
      cursor: 'default',
      _focus: {
        borderColor: 'brand.500',
      },
    }}
  />
));

Input.displayName = 'Input';

export default Input;
