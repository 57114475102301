import React from 'react';
import { useScreenClass } from 'react-grid-system';
import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import { Props } from './Props';

const SmartBanner = dynamic(() => import('components/SmartBanner'));

const AuthDesktopLayout = dynamic(() => import('./Desktop'));

const AuthMobileLayout = dynamic(() => import('./Mobile'));

/**
 * Página de autenticação do usuário.
 */
const AuthLayout = ({ children }: Props) => {
  const screenClass = useScreenClass();
  return (
    <React.StrictMode>
      <SmartBanner />
      <NextSeo title="Login" />
      {['xxl', 'xl', 'lg'].includes(screenClass) ? (
        <AuthDesktopLayout>{children}</AuthDesktopLayout>
      ) : (
        <AuthMobileLayout>{children}</AuthMobileLayout>
      )}
    </React.StrictMode>
  );
};

export default AuthLayout;
