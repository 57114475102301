import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Col } from 'react-grid-system';
import Image from 'next/image';
import pkg from '../../../package.json';
import { Props } from './Props';
import AuthFormArea from './components/FormArea';
import FormMessages from './components/FormMessages';
import SocialIcons from 'components/SocialIcons';
import justaLogo from '../../../public/img/logo-white.svg';
import loginBannerPath from '../../../public/img/banner_login_conta_justa.png';
//
const AuthDesktopLayout = ({ children }: Props) => {
  return (
    <React.Fragment>
      <Flex
        w="100vw"
        h="100vh"
        bgPosition="center"
        bgRepeat="repeat"
        overflow="hidden"
      >
        <Box
          as={Col}
          display="flex"
          flexDir="column"
          justifyContent="space-between"
          maxH="100vh"
          maxW="656px"
        >
          <Box
            w="100%"
            h="100%"
            zIndex={-1}
            __css={{
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(24, 59, 101, 0.7)',
                zIndex: 1,
              },
            }}
          >
            <Image
              src={loginBannerPath}
              layout="fill"
              objectPosition="top"
              objectFit="cover"
              quality={100}
            />
          </Box>
          <Flex
            flexDir="column"
            justifyContent="flex-end"
            alignItems="center"
            color="white"
            w="100%"
            h="100%"
          >
            <Box position="relative" w="306px" h="118px">
              <Image
                src={justaLogo}
                alt="Logo Justa Soluções Financeiras S.A."
                quality={100}
                layout="fill"
              />
            </Box>
            <Text
              fontSize="3xl"
              maxW={350}
              fontWeight={500}
              textAlign="center"
              lineHeight="44px"
              fontFamily="Montserrat"
              mt={4}
              mb={12}
            >
              Conectamos as suas vendas aos benefícios mais justos.
            </Text>
            <SocialIcons />
            <Box w="100%" mb={9} ml="4rem">
              <Text
                color="#FFFFFF"
                fontSize="14px"
                fontWeight="400"
                fontFamily="Montserrat"
              >
                v {pkg?.version}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          as={Col}
          display="flex"
          flexDir="column"
          h="100%"
          overflowY="auto"
          overflowX="hidden"
          justifyContent="center"
          alignItems="center"
          bg="white"
          w="55%"
          maxW="55%"
          lg={7}
          xl={7}
          xxl={7}
          p="50px"
        >
          <AuthFormArea mx={56} message={<FormMessages />}>
            {children}
          </AuthFormArea>
        </Box>
      </Flex>
    </React.Fragment>
  );
};

export default AuthDesktopLayout;
