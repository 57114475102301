import { parseCookies } from 'nookies';
import create from 'zustand';

type SideNavProps = {
  sideNavIsOpened: boolean;
  setSideNavIsOpened: (value: boolean) => void;
};

export const useSideNavStore = create<SideNavProps>(set => {
  const cookies = parseCookies();
  const sideNavIsOpened = cookies.sidenavIsOpen === `true`;

  const initialState = {
    sideNavIsOpened,
  };

  return {
    ...initialState,

    setSideNavIsOpened: () =>
      set(state => ({
        sideNavIsOpened: !state.sideNavIsOpened,
      })),
  };
});
