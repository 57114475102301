import { createIcon } from '@chakra-ui/icons';

const JstFacebook = createIcon({
  defaultProps: {
    fill: 'currentcolor',
  },
  displayName: 'JstFacebook',
  viewBox: '0 0 24 24',
  d: 'M 0 12.067 C 0 18.033 4.22219 22.994 9.74426 24 V 15.333 H 6.82098 V 12 H 9.74426 V 9.333 C 9.74426 6.333 11.6278 4.667 14.2919 4.667 C 15.1358 4.667 16.0459 4.8 16.8897 4.933 V 8 H 15.3959 C 13.9664 8 13.642 8.733 13.642 9.667 V 12 H 16.7601 L 16.2408 15.333 H 13.642 V 24 C 19.164 22.994 23.3862 18.034 23.3862 12.067 C 23.3862 5.43 18.1243 0 11.6931 0 C 5.2619 0 0 5.43 0 12.067 Z',
});

export default JstFacebook;
