import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Col } from 'react-grid-system';
import { Props } from './Props';
import AuthFormArea from './components/FormArea';
import FormMessages from './components/FormMessages';
import loginBannerPath from '../../../public/img/banner_login_conta_justa.png';
import Image from 'next/image';

const AuthMobileLayout = ({ children }: Props) => {
  return (
    <React.Fragment>
      <Box display="flex" h="439px" maxH="100vh">
        <Flex
          flexDir="column"
          justifyContent="flex-end"
          alignItems="center"
          color="white"
          w="100%"
          h="100%"
        >
          <Box
            w="100%"
            h="100%"
            zIndex={-1}
            __css={{
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgb(24, 59, 101, 0.7)',
                zIndex: 1,
              },
            }}
          >
            <Image
              src={loginBannerPath}
              layout="fill"
              objectPosition={'bottom'}
              objectFit="cover"
              quality={100}
            />
          </Box>
          <Text
            fontSize="32px"
            maxW={350}
            fontWeight={500}
            textAlign="center"
            lineHeight="39px"
            fontFamily="Montserrat"
          >
            Bem-vindo(a) à <Text as="strong">Conta Justa!</Text>
          </Text>

          <Box w="100%" mb={9} ml="4rem"></Box>
        </Flex>
      </Box>
      <Box
        as={Col}
        display="flex"
        flexDir="column"
        overflowY="auto"
        overflowX="hidden"
        justifyContent="unset"
        alignItems="center"
        bg="white"
        w="55%"
        maxW="55%"
        lg={7}
        xl={7}
        xxl={7}
      >
        <AuthFormArea message={<FormMessages />} mx={56}>
          {children}
        </AuthFormArea>
      </Box>
    </React.Fragment>
  );
};

export default AuthMobileLayout;
